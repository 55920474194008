import Splide from "@splidejs/splide";
import "@splidejs/splide/dist/css/splide-core.min.css";

var basics = document.querySelectorAll(".splide.basic-splide");

var baseConfig = {
  type: "loop",
  perPage: 3,
  perMove: 1,
  gap: "2rem",
  arrows: false,
  pagination: false,
  breakpoints: {
    992: {
      perPage: 2
    },
    768: {
      perPage: 1
    }
  }
}

for (var i = 0; i < basics.length; i++) {
  var splideConfig;
  var dataAttr = basics[i].dataset.config;
  switch (dataAttr) {
    case 'teaser':
      splideConfig = {
        ...baseConfig,
        perPage: 1,
        breakpoints: {}
      };
      break;
    default:
      splideConfig = baseConfig;
  }
  new Splide(basics[i], splideConfig).mount();
}

document.querySelectorAll(".thumbnail-splide").forEach((slider) => {
  let thumbnails = slider.querySelector(".thumbnails");
  let main = slider.querySelector(".main-slide");
  var thumbnailsSlider = new Splide(thumbnails, {
    fixedWidth: 100,
    fixedHeight: 100,
    gap: false,
    rewind: true,
    pagination: false,
    arrows: false,
    cover: true,
    isNavigation: true,
    breakpoints: {
      600: {
        fixedWidth: 60,
        fixedHeight: 60,
      },
    },
  });
  let mainSlider = new Splide(main);
  mainSlider.sync(thumbnailsSlider);
  mainSlider.mount();
  thumbnailsSlider.mount();
});
